var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "py-0", attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", "align-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { "text-xs-center": "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      attrs: { cols: "12", xl: "10", "offset-xl": "1" },
                    },
                    [
                      _c(
                        "v-container",
                        { staticClass: "text-center py-0" },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "caption",
                                          attrs: { cols: "12", sm: "12" },
                                        },
                                        [
                                          _c("h2", { staticClass: "mb-10" }, [
                                            _vm._v(
                                              "Sei già un nostro cliente?"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-10",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                "x-large": "",
                                                height: "80",
                                                color: "white",
                                                ripple: {
                                                  class: "AXAdarkIndigo--text",
                                                },
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.tracciamento("axa")
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "mb-0 mr-5" },
                                                [_vm._v("Si")]
                                              ),
                                              _c(
                                                "v-avatar",
                                                {
                                                  attrs: {
                                                    tile: "",
                                                    size: "40",
                                                    width: "40",
                                                    height: "40",
                                                  },
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src: require("@/assets/images/axa-logo.svg"),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                "x-large": "",
                                                height: "80",
                                                color: "white",
                                                ripple: {
                                                  class: "AXAdarkIndigo--text",
                                                },
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.tracciamento("mps")
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "mb-0 mr-5" },
                                                [_vm._v("Si")]
                                              ),
                                              _c(
                                                "v-avatar",
                                                {
                                                  attrs: {
                                                    tile: "",
                                                    size: "40",
                                                    width: "110",
                                                    height: "40",
                                                  },
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src: require("@/assets/images/mps-logo.svg"),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                "x-large": "",
                                                height: "80",
                                                color: "white",
                                                ripple: {
                                                  class: "AXAdarkIndigo--text",
                                                },
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.tracciamento(
                                                    "no_cliente"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "mb-0 mr-5" },
                                                [_vm._v("Non ancora")]
                                              ),
                                              _c(
                                                "v-avatar",
                                                {
                                                  attrs: {
                                                    tile: "",
                                                    size: "40",
                                                    width: "40",
                                                    height: "40",
                                                  },
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src: require("@/assets/images/icona-nocliente.png"),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }