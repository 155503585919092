<template>
    <v-container fluid fill-height class="py-0">
        <v-layout justify-center align-center>
            <v-flex text-xs-center>
                <v-row>
                    <v-col cols="12" xl="10" offset-xl="1" class="pb-0">
                        <v-container class="text-center py-0">
                            <v-layout>
                                <v-flex>
                                    <!-- title -->
                                    <v-row align="center" justify="center">
                                        <v-col cols="12" sm="12" class="caption">
                                            <h2 class="mb-10">Sei già un nostro cliente?</h2>
                                        </v-col>
                                    </v-row>
                                    <!-- button -->
                                    <v-row>
                                        <v-col cols="12" class="mb-10">
                                            <!-- axa -->
                                            <v-btn x-large height="80" class="ma-2" color="white"
                                                   @click="tracciamento('axa')"
                                                   :ripple="{ class: 'AXAdarkIndigo--text' }">
                                                <p class="mb-0 mr-5">Si</p>
                                                <v-avatar tile size="40" width="40" height="40">
                                                    <v-img src="@/assets/images/axa-logo.svg"/>
                                                </v-avatar>
                                            </v-btn>
                                            <!-- mps -->
                                            <v-btn x-large height="80" class="ma-2" color="white"
                                                   @click="tracciamento('mps')"
                                                   :ripple="{ class: 'AXAdarkIndigo--text' }">
                                                <p class="mb-0 mr-5">Si</p>
                                                <v-avatar tile size="40" width="110" height="40">
                                                    <v-img src="@/assets/images/mps-logo.svg"/>
                                                </v-avatar>
                                            </v-btn>
                                            <!-- none -->
                                            <v-btn x-large height="80" class="ma-2" color="white"
                                                   @click="tracciamento('no_cliente')"
                                                   :ripple="{ class: 'AXAdarkIndigo--text' }">
                                                <p class="mb-0 mr-5">Non ancora</p>
                                                <v-avatar tile size="40" width="40" height="40">
                                                    <v-img src="@/assets/images/icona-nocliente.png"/>
                                                </v-avatar>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-col>
                </v-row>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {getCookieValue} from '@/utils/utils';

export default {
    name: "chiseiBody",
    data() {
        return {
            idSession: sessionStorage.getItem('sessionId'),
        };
    },
    computed: {
        ...mapGetters({
            getCustomerType: 'params/getCustomerType'
        })
    },
    methods: {
        ...mapMutations({
            'setIsParent': 'params/setIsParent',
        }),
        ...mapActions({
            actionCustomerType: 'params/actionCustomerType'
        }),
        tracciamento(customerType) {
            var click_type;
            if (customerType == 'no_cliente') {
                click_type = 'No';
            } else {
                click_type = customerType.toUpperCase();
            }
            let _this = this;
            let utm_source = sessionStorage.getItem("utm_source");
            let utm_campaign = sessionStorage.getItem("utm_campaign");
            window.tc_events_global(this.$el, 'xt_click', {
                'level_2_number': 'AXA_PerNoi',
                'click_name': 'Prosegui_snodo',
                'click_type': click_type
            });

            grecaptcha.ready(function () {
                grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {action: 'login'}).then(function (captchaToken) {
                    let query = {
                        captcha: captchaToken,
                        type: customerType,
                        utm_source: utm_source,
                        utm_campaign: utm_campaign
                    };
                    let url_app_lead = process.env.VUE_APP_LEAD + '/' + sessionStorage.getItem('leadId');

                    axios.post(url_app_lead, query)
                        .then(resp => {
                            _this.actionCustomerType(customerType);
                            _this.$router.push('/axa/chi_ami');
                        });
                });
            });
        }
    }
};
</script>
